<template>
    <div class="social-bar">
        <div class="inner">
            <a :href="instagram.url">
                <img :src="instagram.image" alt="Besök oss på Instagram">
            </a>
            <a :href="untappd.url">
                <img :src="untappd.image" alt="Besök oss på Untappd">
            </a>
            <a :href="facebook.url">
                <img :src="facebook.image" alt="Besök oss på Facebook">
            </a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            instagram: {
                url: 'https://instagram.com/ardeabrygghus',
                image: require('../assets/instagram.svg')
            },
            facebook: {
                url: 'https://facebook.com/ardeabrygghus',
                image: require('../assets/facebook.svg')
            },
            untappd: {
                url: 'https://untappd.com/ardea-brygghus',
                image: require('../assets/untappd.svg')
            }
        }
    }
}
</script>

<style scoped lang="scss">
    .social-bar {
        background: #fff;
        height: 5rem;
        margin: -1.5rem 0 1.5rem;

        .inner {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            margin: 0 auto;
            padding: 0 5rem;

            @media screen and (max-width: 48rem) {
                padding: 0;
            }
        }

        a {
            display: flex;
            height: 5rem;
            justify-content: center;
        }

        img {
            max-height: 4.25rem;
        }
    }
</style>
