<template>
    <b-container>
        <b-card-group columns>
            <b-card
                :title="beer.name"
                :img-src="beer.image"
                img-top
                tag="article"
                style="max-width: 30rem;
                    margin-left: auto;
                    margin-right: auto;
                    border: 0;"
                class="mb-2"
                v-for="beer in beers[0]" :key="beer.name"
            >
                <b-card-text>
                    {{ beer.text }}
                </b-card-text>
            </b-card>
        </b-card-group>
    </b-container>
</template>

<script>
export default {
    name: 'Mainer',
    data() {
        return {
            beers: [{
                pariah: {
                    image: require('../assets/beers/pariah.jpg'),
                    name: 'Pariah',
                    text: 'Trappisternas mellanbarn - dubbeln! En djup och fyllig upplevelse, med en karamellig sötma gentemot en fruktig och lätt bakgrund av estrig belgojäst och en clean finish.'
                },
                josi: {
                    image: require('../assets/beers/josi.jpg'),
                    name: 'Josi Pale Ale',
                    text: 'Klassisk pale ale bryggd med modern humle. Smaker av krusbär, vindruvor, torkad ananas och svarta vinbär. Perfekt för en varm sommardag vid sjön med goda vänner!'
                },
                nattmaran: {
                    image: require('../assets/beers/nattmaran.jpg'),
                    name: 'Nattmaran',
                    text: 'Prost! När Oktoberfest är som allra bäst är när det serveras bayersk märzen. Här är vår tolkning – helt enkelt en väldigt clean, maltdriven, och förrädiskt lättdrucken klassisk märzen.'
                },
                jolly: {
                    image: require('../assets/beers/jolly_josi.jpg'),
                    name: 'Jolly Josi',
                    text: 'En traditionell engelsk brown ale med chokladtoner, kryddad med kardemumma och nejlika. Denna öl utlovar en varm julkänsla medan du sitter vid elden och ser snön falla utanför fönstret.'
                },
                empire: {
                    image: require('../assets/beers/empire.jpg'),
                    name: 'Empire Stout',
                    text: 'Amerikansk imperial stout med kraftigt rostade toner av choklad och kaffe. Med en mycket värmande hetta är detta robusta öl det perfekta sällskapet under kyliga kvällar.'
                },
                crusader: {
                    image: require('../assets/beers/crusader.jpg'),
                    name: 'Crusader',
                    text: 'Amerikansk IPA av den gamla skolan - tänk granbarr, citrus, och grape på en canvas av karamellig malt och en torr finish med hög beska. Precis vad vi saknat de senaste tio åren!'
                },
                dimman: {
                    image: require('../assets/beers/dimman.jpg'),
                    name: 'Dimman',
                    text: 'Modern IPA i New England-stil, med fruktig nyzeeländsk humle som för tankarna till paradisliknande öar. Grymt läskande och bedrägligt lättdrucken!'
                },
                demon: {
                    image: require('../assets/beers/demon.jpg'),
                    name: 'Demon',
                    text: 'Dubbelmäskad rysk imperial stout, fullproppad med nattsvart malt. Detta är en tjock, fyllig stout som är oförlåtligt rostad, men ändock med en söt och rund finish.'
                }
            }]
        }
    }
}
</script>
