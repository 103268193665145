<template>
    <main class="main" ref="main">
        <Home />
    </main>
</template>

<script>
import 'prefixfree'
import 'no-darkreader'
import Home from './components/Home.vue'

export default {
    name: 'App',
    components: {
        Home
    }
}
</script>
