<template>
    <footer class="container">
        <div class="ardea">
            <img :src="ardea">
        </div>
        <div>
            <h2>Hör av dig!</h2>
            <b-form @submit="onSubmit" @reset="onReset" v-if="show && !sent">
                <b-container class="form-container">
                    <div class="form-row">
                        <b-col>
                            <b-form-input
                                v-model="form.name"
                                placeholder="Namn"
                                required
                            ></b-form-input>
                        </b-col>

                        <b-col>
                            <b-form-input
                                v-model="form.email"
                                type="email"
                                placeholder="E-post"
                                required
                            ></b-form-input>
                        </b-col>
                    </div>

                    <div class="form-row textbox">
                        <b-form-textarea
                            v-model="form.message"
                            :state="form.message.length >= 10"
                            placeholder="Vad har du på hjärtat?"
                            rows="3"
                            required
                        ></b-form-textarea>
                    </div>

                    <b-button type="submit" variant="success">Skicka!</b-button>
                </b-container>
            </b-form>
            <b-card class="mt-3" header="Message sent!" v-if="sent">
                <p class="m-0">
                    Tack! Vi hör av oss så snart som möjligt ✌️
                </p>
            </b-card>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            form: {
                name: '',
                email: '',
                message: ''
            },
            show: true,
            sent: false,
            ardea: require('../assets/logo.svg')
        }
    },
    methods: {
        onSubmit(e) {
            e.preventDefault()

            const data = new FormData()
            data.append('Name', this.form.name)
            data.append('E-mail', this.form.email)
            data.append('Message', this.form.message)

            fetch('https://formspree.io/f/mnqlrgbo', {
                method: 'POST',
                body: data,
                headers: {
                    'Accept': 'application/json'
                }
            }).then(() => {
                this.onReset(e)
            }).catch(err => {
                console.dir(err)
            })
        },
        onReset(e) {
            e.preventDefault()

            // Reset our form values
            this.form.email = ''
            this.form.name = ''
            this.form.message = ''

            // Trick to reset/clear native browser form validation state
            this.show = false
            this.$nextTick(() => {
                this.show = true
                this.sent = true
            })
        }
    }
}
</script>

<style scoped lang="scss">
    footer {
        display: grid;
        grid-template-columns: 1fr 1fr;

        @media screen and (max-width: 64rem) {
            grid-template-columns: 1fr;

            .ardea {
                order: 2;
            }
        }

        @media screen and (min-width: 64rem) {
            .ardea img {
                max-height: 100vh;
            }

            h2 {
                text-align: left;
                padding: 2rem 0 0;
            }
        }
    }

    form {
        .form-container {
            display: flex;
            flex-direction: column;
        }

        @media screen and (min-width: 64rem) {
            height: 100%;

            .form-container {
                padding: 0;
                height: 100%;
            }

            .textbox {
                height: 62%;
            }
        }
    }

    input:focus {
        box-shadow: none;
        border-color: #86b7fe;
    }

    .form-row {
        display: flex;
        margin: .25rem 0;
        gap: .5rem;
    }

    button {
        margin-top: .25rem;
        align-self: end;
    }

    textarea {
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #212529;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:focus {
            border-color: #86b7fe;
        }

        &:focus-visible {
            outline: 0;
        }
    }
</style>
