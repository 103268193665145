<template>
    <div>
        <Header />
        <Social />
        <Mainer />
        <Footer />
    </div>
</template>

<script>
import Header from './Header'
import Social from './Social'
import Mainer from './Mainer'
import Footer from './Footer'

export default {
    name: 'Home',
    components: {
        Header,
        Social,
        Mainer,
        Footer
    }
}
</script>
