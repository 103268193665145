<template>
    <header :style="{ backgroundImage: 'url(' + images.josi + ')' }">
        <Logo :platform="vssWidth <= 1024 ? 'mobile' : 'desktop'" />
        <p :style="fontLoaded === true ? { 'opacity': 1, 'margin-top': '-5rem' } : { 'opacity': 0, 'margin-top': '-7rem' }">Handcrafted Beer From Stockholm</p>
    </header>
</template>

<script>
import Logo from './Logo'

export default {
    name: 'Header',
    components: {
        Logo
    },
    data() {
        return {
            images: {
                josi: require('../assets/background_josi.jpg')
            },
            event: null,
            vssWidth: null,
            fontLoaded: false
        }
    },
    mounted() {
        this.handleResize()
        window.addEventListener('resize', this.handleResize)

        if ('fonts' in document)
            document.fonts.ready.then(() => {
                this.fontLoaded = true
            })
        else this.fontLoaded = true
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        getScreenWidth() {
            return window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth
        },

        handleResize(event) {
            this.event = event
            this.vssWidth = this.getScreenWidth()
        },

        $vssDestroyListener() {
            window.removeEventListener('resize', this.handleResize)
        }
    }
}
</script>

<style scoped lang="scss">
    header {
        min-height: 100vh;
        display: grid;
        grid-template-rows: 2fr 1fr;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: #181818;
        padding: 0;
        background-size: cover;
        background-position: center;
        margin: 0 0 1.5rem;
        background-attachment: fixed;
    }

    p {
        color: white;
        filter: drop-shadow(2px 2px 2px black);
        font-size: calc(60px + 6 * ((100vw - 320px) / 680));
        font-family: BeautySchoolDropoutII, monospace;
        margin: -5rem 0 0;
        align-self: start;
        padding: 0 2.7rem;
        justify-self: center;
        word-spacing: -1rem;
        letter-spacing: -.3px;
        transition: 1s all;
    }

    @media screen and (max-width: 64rem) {
        header {
            grid-template-rows: 5rem 1fr;
            grid-template-columns: 1fr 2fr;
        }

        p {
            grid-row: 2;
            grid-column: 1 / -1;
            font-size: 22vw;
            padding: 0;
            line-height: 1;
            max-width: 100vw;
            align-self: center;
        }
    }
</style>
